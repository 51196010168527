<template>
  <!-- Start Contact -->
  <div class="py-5">
    <!-- <div class="cs-height_140 cs-height_lg_80"></div> -->
    <div class="row mb-5 ">
      <div class="cs-section_heading cs-style2 cs-size2 text-center wow fadeInUp" data-wow-duration="1s"
      data-wow-delay="0.2s">
      <h2 class="cs-section_title">
        <b class="cs-accent_color">Contact us</b>
      </h2>
    </div>
      <!-- <h3 class="text-center mb-2">Contact us</h3> -->
    </div>
    <div class="container">
      <div class="row">
        <div class="col-lg-6 offset-lg-6">
          <div class="position-relative">
            <div class="cs-contact cs-style1">
              <h4 class="cs-contact_title cs-bold">Get In Touch</h4>

              <div v-if="alert" class="alert alert-success alert-dismissible fade show" role="alert">
                <strong>{{ alert }}</strong>
                <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
              </div>

              <form class="cs-contact_form">
                <input
                  type="text"
                  class="cs-form_field cs-accent_5_bg_2 cs-accent_30_border_2 cs-primary_color"
                  placeholder="Your name"
                  v-model="form.name"
                />
                <span v-if="errors.name" class="text-danger">{{
                  errors.name[0]
                }}</span>
                <div class="cs-height_20 cs-height_lg_20"></div>
                <input
                  type="text"
                  class="cs-form_field cs-accent_5_bg_2 cs-accent_30_border_2 cs-primary_color"
                  placeholder="Subject"
                  v-model="form.subject"
                />
                <span v-if="errors.subject" class="text-danger">{{
                  errors.subject[0]
                }}</span>
                <div class="cs-height_20 cs-height_lg_20"></div>
                <input
                  type="text"
                  class="cs-form_field cs-accent_5_bg_2 cs-accent_30_border_2 cs-primary_color"
                  placeholder="Email"
                  v-model="form.email"
                />
                <span v-if="errors.email" class="text-danger">{{
                  errors.email[0]
                }}</span>
                <div class="cs-height_20 cs-height_lg_20"></div>
                <input
                  type="number"
                  class="cs-form_field cs-accent_5_bg_2 cs-accent_30_border_2 cs-primary_color"
                  placeholder="Phone"
                  v-model="form.phone"
                />
                <span v-if="errors.phone" class="text-danger">{{
                  errors.phone[0]
                }}</span>
                <div class="cs-height_20 cs-height_lg_20"></div>
                <textarea
                  cols="30"
                  rows="5"
                  class="cs-form_field cs-accent_5_bg_2 cs-accent_30_border_2 cs-primary_color"
                  placeholder="Your Message"
                  v-model="form.message"
                ></textarea>
                <span v-if="errors.message" class="text-danger">{{
                  errors.message[0]
                }}</span>
                <div class="cs-height_20 cs-height_lg_20"></div>

                <button
                  class="cs-btn cs-style7 cs-accent_bg cs-white cs-accent_border cs-accent_10_bg_hover cs-accent_30_border_hover cs-btn_md cs-rounded cs-primary_font cs-medium "
                  @click.prevent="handleSubmit"
                >
                  Send Message
                </button>
              </form>
              <div class="cs-height_45 cs-height_lg_25"></div>
            </div>
            <div class="cs-height_140 cs-height_lg_0"></div>
            <img
              src="../assets/saas/contact1.png"
              alt=""
              class="cs-contact_1_img"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- <div class="cs-height_140 cs-height_lg_80"></div> -->
  <!-- End Contact -->
</template>

<script>
export default {

  data() {
    return {
      wp_cms: process.env.VUE_APP_API_URL,
      errors: {},
      alert: "",

      form: {
        client_id: process.env.VUE_APP_FE_CLIENT_ID,
        name: "",
        subject: "",
        email: "",
        phone: "",
        message: "",
      },
     
    };
  },

  mounted() {
    let Script = document.createElement("script");
    Script.setAttribute("src", "https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/js/bootstrap.bundle.min.js");
    document.head.appendChild(Script);
  },

  methods: {

    handleSubmit() {
      this.axios
        .post(this.wp_cms + "api/contact/store", this.form)
        .then((response) => {
        
          this.form.name = "";
          this.form.subject = "";
          this.form.email = "";
          this.form.phone = "";
          this.form.message = "";
          this.errors = "";

          this.alert = response.data.status;
          console.log(this.alert);

        })
        
        .catch((error) => {
          this.errors = error.response.data.errors;
        });
    },
  },
};
</script>
