<template>

  <!-- Start Testimonial -->
  <div>
    <div class=" py-5 " v-if="!stores.length == 0"></div>
    <div class="container wow fadeInUp" data-wow-duration="1s" data-wow-delay="0.2s">
      <div class="cs-section_heading cs-style2 text-center cs-size3">
        <!-- <div class="cs-section_subtitle">Clients feedback</div> -->
        <h2 class="cs-section_title">Careers  <b class="cs-accent_color"> With Us</b></h2>
      </div>
      <div class="cs-height_70 cs-height_lg_50"></div>
    </div>
    <div class="container wow fadeIn" data-wow-duration="1s" data-wow-delay="0.3s">
      <div class="row">
        <div class="col-lg-10 offset-lg-1">
          <div class="cs-slider cs-style1 cs-remove_overflow"  v-if="!stores.length == 0">
            <div class="cs-slider_container" data-autoplay="0" data-loop="1" data-speed="600" data-center="0" data-fade-slide="1" data-slides-per-view="1">
              <div class="cs-slider_wrapper">

                <div class="cs-slide" v-for="(career, index) in stores" :key="index">
                  <div class="cs-testimonial cs-style3">
                    <div class="cs-testimonial_left">
                      <div class="cs-testimonial_icon cs-center cs-white_bg cs-accent_color_2"><i class="fas fa-quote-left"></i></div>
                      <div class="cs-testimonial_text" v-html="career.positions[0]['description']"></div>
                      <div class="cs-testimonial_info">
                        <div class="cs-testimonial_meta">
                          <div class="cs-testimonial_avatar_designation"> {{career.positions[0]['title']}} </div>
                        </div>
                      </div>
                    </div>
                    <div class="cs-testimonial_avatar" data-src="../assets/img/hosting/testimonial1.jpg"></div>
                  </div>
                </div>

              </div>
            </div><!-- .cs-slider_container -->
            <div class="cs-pagination cs-style1 cs-hidden"></div>
            <div class="cs-slider_arrows cs-style2 cs-type1 cs-white_bg cs-color1">
              <div class="cs-slider_arrows_in">
                <div class="cs-left_arrow cs-center cs-accent_color cs-accent_15_bg cs-accent_bg_hover cs-white_hover">
                  <i class="fas fa-long-arrow-alt-left"></i>
                </div>
                <div class="cs-right_arrow cs-center cs-accent_color cs-accent_15_bg cs-accent_bg_hover cs-white_hover">
                  <i class="fas fa-long-arrow-alt-right"></i>
                </div>
              </div>
            </div>
          </div><!-- .cs-slider -->
        </div>
      </div>
    </div>
    <!-- <div class="cs-height_175 cs-height_lg_115"></div> -->
  </div>
  <!-- End Testimonial -->

</template>

<script>
export default {
  name: "Positions",
  props: {
    Stores: Array,
    Per_Page: Number,
  },

  data() {
    return {
      stores: [],
    };
  },

  mounted() {
    console.log("Albums65555555555:", this.stores);
  },
  watch: {
    Stores() {
      if (this.Stores != null) {
        this.stores = this.Stores.filter(
          (item, index) => index < this.Per_Page
        );
        console.log("[mounted stores]", this.stores);
      }
    },
  },
};
</script>
