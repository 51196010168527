<template>
  <div>
    <!-- Start Gallery -->
    <div class="container pt-5">
      <div class="row" v-if="albums !== undefined">
        <div class="cs-section_heading cs-style2 cs-size2 text-center wow fadeInUp" data-wow-duration="1s"
          data-wow-delay="0.2s">
          <h2 class="cs-section_title">
            <b class="cs-accent_color" v-if="albums !== undefined">Events</b>
          </h2>
        </div>
      </div>
    </div>
    <div class="container py-5">
      <div class="" v-if="albums !== undefined">
        <div class="row d-flex justify-content-start">
          <div class="col-12 col-md-6 col-lg-4 pb-3 d-flex align-content-stretch " v-for="(element, index) in albums" :key="index">
            <div class="cs-icon_box cs-style3 cs-color1 text-left wow fadeIn cs-icon_box_new d-flex flex-column justify-content-between" data-wow-duration="1s"
              data-wow-delay="0.25s" @click="openLightbox(element)">
              <div class="">
              <h4 class="fs-6 d-flex h-auto ">
                {{ element["title"] }}
              </h4>
            </div>
              <div class=" cs-center">
                <!-- Display only the first image -->
                <div v-if="Array.isArray(element['image'])">
                  <img :src="wp_cms + 'storage/' + element['image'][0]" class=" cs-lightbox_img " />
                </div>
                <div v-else>
                  <img :src="wp_cms + 'storage/' + JSON.parse(element['image'])[element['thumbnail_image_index']]" class="cs-lightbox_img " />
                </div>
              </div>
            </div>
            <!-- <div class="cs-height_30 cs-height_lg_30"></div> -->
          </div>
        </div>
      </div>
    </div>
    <!-- End Gallery -->


    <!-- Lightbox or Modal -->
    <div v-if="lightboxOpen" class="cs-lightbox">
      <div class="cs-lightbox_content">
        <span class="cs-lightbox_close" @click="closeLightbox">&times;</span>
        <div class="cs-lightbox_slides flex-wrap">
          <div v-for="(image, index) in currentAlbumImages" :key="index">
            <img :src="wp_cms + 'storage/' + image" alt="" class=" p-2 " />
          </div>
        </div>
        <!-- Controls -->
        <!-- <a class="cs-lightbox_prev" @click="prevSlide">&#10094;</a>
        <a class="cs-lightbox_next" @click="nextSlide">&#10095;</a> -->
      </div>
    </div>
    <!-- End Lightbox or Modal -->
  </div>
</template>

<script>
export default {
  name: "Gallery",
  props: {
    albums: Array,
  },
  data() {
    return {
      wp_cms: process.env.VUE_APP_API_URL,
      lightboxOpen: false,
      currentAlbumImages: [],
      currentAlbumIndex: null,
    };
  },
  methods: {
    openLightbox(album) {
      this.lightboxOpen = true;
      this.currentAlbumImages = Array.isArray(album.image) ? album.image : JSON.parse(album.image);
      this.currentAlbumIndex = 0;
    },
    closeLightbox() {
      this.lightboxOpen = false;
      this.currentAlbumImages = [];
      this.currentAlbumIndex = null;
    },
    prevSlide() {
      if (this.currentAlbumIndex > 0) {
        this.currentAlbumIndex--;
      }
    },
    nextSlide() {
      if (this.currentAlbumIndex < this.currentAlbumImages.length - 1) {
        this.currentAlbumIndex++;
      }
    },
  },
};
</script>

<style scoped>
/* Add your component-specific CSS styles here */

.cs-lightbox_img {
  border-radius: 16px;
  width: 300px;
  height: 300px;
  /* max-width: 100%; 
  max-height: 300px; */
  object-fit: cover;
}

.cs-lightbox {
  display: block;
  position: fixed;
  z-index: 9999;
  background-color: rgba(0, 0, 0, 0.9);
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  /* Enable scrolling */
}

.cs-lightbox_content {
  position: relative;
  margin: auto;
  width: 80%;
  max-width: 1200px;
  /* height: 80%; Remove fixed height */
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 24px;
}

.cs-lightbox_close {
  position: absolute;
  top: 15px;
  right: 35px;
  color: white;
  font-size: 40px;
  font-weight: bold;
  transition: 0.3s;
}

.cs-lightbox_close:hover,
.cs-lightbox_close:focus {
  color: #bbb;
  text-decoration: none;
  cursor: pointer;
}

.cs-lightbox_slides {
  margin-top: 12px;
  width: 100%;
  /* height: 100%; Remove fixed height */
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  /* Allow images to wrap */
  gap: 16px;
  /* Add gap between images */
}

.cs-lightbox_slides img {
  width: 100vw;
  height: auto;
  /* max-width: 100%; 
  max-height: 300px; */
  object-fit: cover;
  border-radius: 16px;
}

.cs-lightbox_prev,
.cs-lightbox_next {
  cursor: pointer;
  position: absolute;
  top: 50%;
  width: auto;
  padding: 16px;
  margin-top: -22px;
  color: white;
  font-weight: bold;
  font-size: 20px;
  transition: 0.6s ease;
  border-radius: 0 3px 3px 0;
}

.cs-lightbox_next {
  right: 0;
  border-radius: 3px 0 0 3px;
}
</style>
