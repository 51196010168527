<template>
  <Theme1 v-if="themeLayout === 'theme-1'" :client="client" />
  <Theme2 v-else-if="themeLayout === 'theme-2'" :client="client" />
  <Theme3 v-else-if="themeLayout === 'theme-3'" :client="client" />
</template>

<script>
import Theme1 from "./pages/Theme1.vue";
import Theme2 from "./pages/Theme2.vue";
import Theme3 from "./pages/Theme3.vue";

export default {
  name: "App",
  components: {
    Theme1,
    Theme2,
    Theme3,
  },

  data() {
    return {
      themeLayout: "theme-2", // temperory
      themeColor: "",

      wp_cms: process.env.VUE_APP_API_URL,
      career_url: process.env.VUE_APP_CAREER_API_URL,
      key: process.env.VUE_APP_CLIENT_KEY,
      client_name: process.env.VUE_APP_CLIENT,

      client_access_key: process.env.VUE_APP_FE_CLIENT_ID,
      
      client: {},
    };
  },

  mounted() {
    this.axios
      .get(this.wp_cms + 'api/admin/single-client-details/' + this.client_access_key)
      .then((response) => {
        console.log(response.data.client);
        this.client = response.data.client;
        document.title = this.client.name;

        this.themeColor = this.client.theme_color;
        console.log(this.themeColor);
        import(`./assets/css/theme_${this.themeColor}.css`);

        this.themeLayout = this.client.theme_option;
        console.log(this.themeLayout);
      })
      .catch((error) => {
        console.log(error);
      });
  },
};
</script>

<style>
.cs-post_pagination.cs-style2 .page-numbers .current:disabled {
  opacity: 0.65;
  border-color: rgb(14 14 14 / 20%);
  cursor: not-allowed;
}
</style>
